<template>
  <div class="quiz-container">
    <div class="quiz-question box">
      <img src="@/assets/logo.png" class="bg-image" />
      <div class="quiz-content">
        <div class="columns is-mobile">
          <div class="column is-one-quater">
            <h2 class="subtitle"><b>{{ $t('question') }}</b></h2>
          </div>
          <div class="column is-half" style="font-size: 1.2em; text-align: right; padding-right: 1.5em;">
            {{ C }} / {{ T }}
          </div>
        </div>

        <h2 class="subtitle" id="qtext"></h2>

        <div class="buttons">
          
          <button v-for="option in options" :key="option.id" 
            @click="check_answer($event.target)"
            :value="option.opt"
            class="button is-medium is-outlined quiz-option" 
          >
            {{ option.opt }}
          </button>
          <br/>
        </div>

        <div id="show-answer" class="show-answer">
          <b>Answer: </b> {{ this.correct }}
          <br/>
        </div>
      </div>
    </div>
    <div class="field is-grouped is-mobile">
      <div class="column control is-half">
        <button class="button is-warning is-fullwidth" @click="reset_page()">{{ $t('reset') }}</button>
      </div>
      <div class="column control is-half">
        <button class="button is-primary is-fullwidth" @click="next_question()">{{ $t('next') }}</button>
      </div>
    </div>
    <Contact />
  </div>
</template>

<script>
import Contact from './Contact.vue'

export default {
  name: 'MusicQuizInterval',
  components: {
    Contact
  },
  data() {
    return {
      interval_chart: [
        // ["DUMMY", "m2", "M2", "m3", "M3", "P4", "P5", "m6", "M6", "m7", "M7", "P8"],
        // ["Ab", "Bbb", "Bb", "Cb", "C", "Db", "Eb", "Fb", "F", "Gb", "G", "Ab"],
        // ["A", "Bb", "B", "C", "C#", "D", "E", "F", "F#", "G", "G#", "A"],
        // ["A#", "B", "B#", "C#", "C##", "D#", "E#", "F#", "F##", "G#", "G##", "A#"],
        // ["Bb", "Cb", "C", "Db", "D", "Eb", "F", "Gb", "G", "Ab", "A", "Bb"],
        // ["B", "C", "C#", "D", "D#", "E", "F#", "G", "G#", "A", "A#", "B"],
        // ["B#", "C#", "C##", "D#", "D##", "E#", "F##", "G#", "G##", "A#", "A##", "B#"],
        // ["Cb", "Dbb", "Db", "Ebb", "Eb", "Fb", "Gb", "Abb", "Ab", "Bbb", "Bb", "Cb"],
        // ["C", "Db", "D", "Eb", "E", "F", "G", "Ab", "A", "Bb", "B", "C"],
        // ["C#", "D", "D#", "E", "E#", "F#", "G#", "A", "A#", "B", "B#", "C#"],
        // ["Db", "Ebb", "Eb", "Fb", "F", "Gb", "Ab", "Bbb", "Bb", "Cb", "C", "Db"],
        // ["D", "Eb", "E", "F", "F#", "G", "A", "Bb", "B", "C", "C#", "D"],
        // ["D#", "E", "E#", "F#", "F##", "G#", "A#", "B", "B#", "C#", "C##", "D#"],
        // ["Eb", "Fb", "F", "Gb", "G", "Ab", "Bb", "Cb", "C", "Db", "D", "Eb"],
        // ["E", "F", "F#", "G", "G#", "A", "B", "C", "C#", "D", "D#", "E"],
        // ["E#", "F#", "F##", "G#", "G##", "A#", "B#", "C#", "C##", "D#", "D##", "E#"],
        // ["Fb", "Gbb", "Gb", "Abb", "Ab", "Bbb", "Cb", "Dbb", "Db", "Ebb", "Eb", "Fb"],
        // ["F", "Gb", "G", "Ab", "A", "Bb", "C", "Db", "D", "Eb", "E", "F"],
        // ["F#", "G", "G#", "A", "A#", "B", "C#", "D", "D#", "E", "E#", "F#"],
        // ["Gb", "Abb", "Ab", "Bbb", "Bb", "Cb", "Db", "Ebb", "Eb", "Fb", "F", "Gb"],
        // ["G", "Ab", "A", "Bb", "B", "C", "D", "Eb", "E", "F", "F#", "G"],
        // ["G#", "A", "A#", "B", "B#", "C#", "D#", "E", "E#", "F#", "F##", "G#"]
        ["DUMMY", "m2", "M2", "m3", "M3", "P4", "P5", "m6", "M6", "m7", "M7", "P8"],
        ["A♭", "B♭♭", "B♭", "C♭", "C", "D♭", "E♭", "F♭", "F", "G♭", "G", "A♭"],
        ["A", "B♭", "B", "C", "C♯", "D", "E", "F", "F♯", "G", "G♯", "A"],
        ["A♯", "B", "B♯", "C♯", "C♯♯", "D♯", "E♯", "F♯", "F♯♯", "G♯", "G♯♯", "A♯"],
        ["B♭", "C♭", "C", "D♭", "D", "E♭", "F", "G♭", "G", "A♭", "A", "B♭"],
        ["B", "C", "C♯", "D", "D♯", "E", "F♯", "G", "G♯", "A", "A♯", "B"],
        ["B♯", "C♯", "C♯♯", "D♯", "D♯♯", "E♯", "F♯♯", "G♯", "G♯♯", "A♯", "A♯♯", "B♯"],
        ["C♭", "D♭♭", "D♭", "E♭♭", "E♭", "F♭", "G♭", "A♭♭", "A♭", "B♭♭", "B♭", "C♭"],
        ["C", "D♭", "D", "E♭", "E", "F", "G", "A♭", "A", "B♭", "B", "C"],
        ["C♯", "D", "D♯", "E", "E♯", "F♯", "G♯", "A", "A♯", "B", "B♯", "C♯"],
        ["D♭", "E♭♭", "E♭", "F♭", "F", "G♭", "A♭", "B♭♭", "B♭", "C♭", "C", "D♭"],
        ["D", "E♭", "E", "F", "F♯", "G", "A", "B♭", "B", "C", "C♯", "D"],
        ["D♯", "E", "E♯", "F♯", "F♯♯", "G♯", "A♯", "B", "B♯", "C♯", "C♯♯", "D♯"],
        ["E♭", "F♭", "F", "G♭", "G", "A♭", "B♭", "C♭", "C", "D♭", "D", "E♭"],
        ["E", "F", "F♯", "G", "G♯", "A", "B", "C", "C♯", "D", "D♯", "E"],
        ["E♯", "F♯", "F♯♯", "G♯", "G♯♯", "A♯", "B♯", "C♯", "C♯♯", "D♯", "D♯♯", "E♯"],
        ["F♭", "G♭♭", "G♭", "A♭♭", "A♭", "B♭♭", "C♭", "D♭♭", "D♭", "E♭♭", "E♭", "F♭"],
        ["F", "G♭", "G", "A♭", "A", "B♭", "C", "D♭", "D", "E♭", "E", "F"],
        ["F♯", "G", "G♯", "A", "A♯", "B", "C♯", "D", "D♯", "E", "E♯", "F♯"],
        ["G♭", "A♭♭", "A♭", "B♭♭", "B♭", "C♭", "D♭", "E♭♭", "E♭", "F♭", "F", "G♭"],
        ["G", "A♭", "A", "B♭", "B", "C", "D", "E♭", "E", "F", "F♯", "G"],
        ["G♯", "A", "A♯", "B", "B♯", "C♯", "D♯", "E", "E♯", "F♯", "F♯♯", "G♯"]
      ],
      options: [
        {id: "A", opt: "A"},
        {id: "B", opt: "B"},
        {id: "C", opt: "C"},
        {id: "D", opt: "D"}
      ],
      direction: "above",
      base: "A",
      dist: "P5",

      correct: 0,
      over: false,

      T: 0, // Total questions answered
      C: 0, // Total correct answers
    }
  },
  mounted() {
    this.new_question()
  },

  methods: {
    generate_options(r, c, above) {
      const rows = this.interval_chart.length;
      const L = Math.floor(Math.random() * 4)
      const R = 4 - L - 1
      var options = []
      for (var i = r - L; i <= r + R; i++) {
        var ri = ((i - 1) + (rows - 1)) % (rows - 1) + 1

        if (above) {
          options.push(this.interval_chart[ri][c])
        }
        else {
          options.push(this.interval_chart[ri][0])
        }
      }
      return options
    },

    generate_question() {
      const rows = this.interval_chart.length
      const cols = this.interval_chart[0].length
      const r = Math.floor(Math.random() * (rows - 1)) + 1
      const c = Math.floor(Math.random() * (cols - 1)) + 1

      var base = this.interval_chart[r][0]
      var dist = this.interval_chart[0][c]
      var result = this.interval_chart[r][c]
      var direction = Math.random()

      if (direction > 0.5) {
        return [ base, dist, result, "above", this.generate_options(r, c, true) ]
      }
      else {
        return [ result, dist, base, "below", this.generate_options(r, c, false) ]
      }
    },

    translate_interval(interval) {
      interval = interval.replace("m", "小")
      interval = interval.replace("M", "大")
      interval = interval.replace("P", "纯")
      return interval + "度"
    },


    new_question() {
      var question = this.generate_question()
      this.base = question[0]
      this.dist = question[1]
      this.correct = question[2]
      this.direction = question[3]
      
      var options = question[4]

      // 1. Reset all buttons
      var buttons = document.getElementsByClassName('quiz-option')
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].className = 'button is-medium is-outlined quiz-option'
      }
      this.over = false

      // 2. Render Options
      for (i = 0; i < options.length; i++) {
        this.options[i]['opt'] = options[i]
      }

      // 3. Hide Answer
      this.show_answer(false)

      // 4. Render Question
      var qtext = document.querySelector('#qtext')
      if (this.$i18n.locale == "zh") {
        var direction = this.direction == "above" ? " 往上" : " 往下"
        var dist = this.translate_interval(this.dist)
        qtext.textContent = this.base + direction + "数 " + dist + " 是哪个音？" 
      }
      else {
        qtext.textContent = this.dist + " " + this.direction + " " + this.base + " is ______."
      }
    },

    next_question() {
      if (this.over) {
        this.new_question()
      }
    },

    check_answer(btn) {
      if (this.over) {
        return ;
      }

      if (btn.value == this.correct) {
        btn.classList.toggle("is-success")
        this.$audio_correct.play()
        this.C++
      }
      else {
        btn.classList.toggle("is-danger")
        if (Math.random() < 0.35) {
          btn.classList.toggle("animate__hinge")
          this.$audio_error1.play()
        }
        else {
          this.$audio_error2.play()
        }
        this.show_answer(true)
      }

      this.T++
      this.over = true
    },

    reset_page() {
      this.over = false
      this.T = 0
      this.C = 0
      this.new_question()
    },

    show_answer(show) {
      var ans_field = document.getElementById("show-answer")
      if (show) {
        ans_field.style.display = 'block'
      }
      else {
        ans_field.style.display = 'none'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
