export default {
  // Global
  question: "问题",
  reset: "重新开始",
  next:  "下一题",

  // Homepage
  h_title:    "乐理练习",
  h_beats:    "节奏练习",
  h_interval: "音程练习",
  h_scale:    "音阶练习",
  h_chord:    "和弦练习",
  h_term:     "音乐术语",

  // Beat
  b_question:  "数一数，一共有多少拍？",

  // Scale
  s_question:  "以下是什么音阶？",

  // Chord
  c_question: "这是什么和弦？",

  // Term
  t_question: "以下音乐术语是什么意思？"
};