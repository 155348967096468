<template>
  <div class="quiz-container">
    <div class="quiz-question box">
      <img src="@/assets/logo.png" class="bg-image" />
      
      <div class="quiz-content">
        <div class="columns is-mobile">
          <div class="column is-one-quater">
            <h2 class="subtitle"><b>{{ $t('question') }}</b></h2>
          </div>
          <div class="column is-half" style="font-size: 1.2em; text-align: right; padding-right: 1.5em;">
            {{ C }} / {{ T }}
          </div>
        </div>

        <h2 class="subtitle" id="qtext">{{ $t('s_question') }}</h2>
        <div class="container">
          <div id="music-notes"></div>
        </div>

        <div class="buttons">
          
          <button v-for="option in options" :key="option.id" 
            @click="check_answer($event.target)"
            :value="option.opt"
            class="button is-medium is-outlined quiz-option" 
          >
            {{ option.opt }}
          </button>
          <br/>
        </div>

        <div id="show-answer" class="show-answer">
          <b>Answer: </b> {{ this.correct }}
          <br/>
        </div>
      </div>

    </div>
    <div class="field is-grouped is-mobile">
      <div class="column control is-half">
        <button class="button is-warning is-fullwidth" @click="reset_page()">{{ $t('reset') }}</button>
      </div>
      <div class="column control is-half">
        <button class="button is-primary is-fullwidth" @click="next_question()">{{ $t('next') }}</button>
      </div>
    </div>

    <Contact />
  </div>
</template>

<script>
import ABCJS from 'abcjs'
import Scale from '../scale.js'
import Contact from './Contact.vue'

export default {
  name: 'MusicQuizScale',
  components: {
    Contact
  },
  data() {
    return {
      options: [
        {id: "A", opt: "A# Major"},
        {id: "B", opt: "A# minor hamonic"},
        {id: "C", opt: "A Major"},
        {id: "D", opt: "A minor melodic"}
      ],
      correct: 0,
      over: false,

      T: 0, // Total questions answered
      C: 0, // Total correct answers
    }
  },
  mounted() {
    this.scaleTool = new Scale()
    this.new_question()
  },

  methods: {
    random_choices(arr, numItems) {
      const result = [];
      for (let i = 0; i < numItems; i++) {
        const randomIndex = Math.floor(Math.random() * arr.length);
        result.push(arr[randomIndex]);
        arr.splice(randomIndex, 1);
      }
      return result;
    },

    random_choice_single(arr) {
      const randomIndex = Math.floor(Math.random() * arr.length);
      return arr[randomIndex];
    },

    generate_question() {
      const clefs = ["treble", "bass"]
      const types = ["Major", "minor hamonic", "minor melodic"]
      const keys = {
        "Major": ["C", "G", "D", "A", "E", "B", "F", "Bb", "Eb", "Ab", "Db"],
        "minor hamonic": ["A", "E", "B", "F#", "A", "C#", "G#", "D", "G", "C", "F", "Bb", "Eb", "Ab"],
        "minor melodic": ["A", "E", "B", "F#", "A", "C#", "G#", "D", "G", "C", "F", "Bb", "Eb", "Ab"]
      }
      const raiseOctave = ["C", "C#", "D", "Cb", "D#"]

      var ansClef = this.random_choice_single(clefs)
      var ansType = this.random_choice_single(types)
      var ansKey  = this.random_choice_single(keys[ansType])

      var qScale = []
      if (ansType == "Major") {
        qScale = this.scaleTool.get_major_scale(ansKey)
      }
      else if (ansType == "minor hamonic") {
        qScale = this.scaleTool.get_harmonic_minor_scale(ansKey)
      }
      else {
        qScale = this.scaleTool.get_melodic_minor_scale_ascending(ansKey)
      }

      var octave = 0
      if (ansClef == "treble") {
        octave = 0
      }
      else if (ansClef == "bass" && raiseOctave.indexOf(ansKey) >= 0 ) {
        octave = -1
      }
      else {
        octave = -2
      }
      var qScaleAbc = this.scaleTool.scale_to_abcjs(qScale, octave, false)
      return [qScaleAbc, ansClef, ansType, ansKey]
    },

    generate_options(ansType, ansKey) {
      const tmpTypes = ["Major", "minor hamonic", "minor melodic"]
      const tmpKeys  = ["C", "G", "D", "A", "E", "B", "F#", "C#", "F", "Bb", "Eb", "Ab", "Db", "Gb", "Cb"]

      var candidateTypes = tmpTypes.filter(function(element) {
        return element !== ansType;
      });
      var candidateKeys  = tmpKeys.filter(function(element) {
        return element !== ansKey;
      });
      var optKey  = this.random_choice_single(candidateKeys)
      var optType = this.random_choice_single(candidateTypes)

      var choices = []
      choices.push( optKey + " " + optType )
      choices.push( ansKey + " " + optType )
      choices.push( optKey + " " + ansType )
      choices.push( ansKey + " " + ansType )

      choices.sort(() => 
        Math.random() - 0.5
      );
      return choices
    },

    translate(text) {
      if (this.$i18n.locale == "zh") {
        text = text.replace("Major", "大调")
        text = text.replace("minor hamonic", "和声小调")
        text = text.replace("minor melodic", "旋律小调")
      }
      return text
    },

    new_question() {
      var tokens = this.generate_question()
      var ansClef = tokens[1]
      var ansType = tokens[2]
      var ansKey  = tokens[3]
      var qnotes = "L:1/4\nV:V1 clef=" + ansClef + "\nK: C\n" + tokens[0]
      var qanswr = ansKey + " " + ansType
      var options = this.generate_options(ansType, ansKey)

      // 1. Reset all buttons
      var buttons = document.getElementsByClassName('quiz-option')
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].className = 'button is-medium is-outlined quiz-option'
      }
      this.over = false

      // 2. Render Question
      var ctl_notes = document.getElementById("music-notes")
      ABCJS.renderAbc(ctl_notes, qnotes, { staffwidth: "350", responsive: "resize" })

      // 3. Render Options
      for (i = 0; i < options.length; i++) {
        this.options[i]['opt'] = this.translate(options[i].replace("#", "♯").replace("b", "♭"))

      }
      this.correct = this.translate(qanswr.replace("#", "♯").replace("b", "♭"))
      this.show_answer(false)
    },

    next_question() {
      if (this.over) {
        this.new_question()
      }
    },

    check_answer(btn) {
      if (this.over) {
        return ;
      }

      if (btn.value == this.correct) {
        btn.classList.toggle("is-success")
        this.$audio_correct.play()
        this.C++
      }
      else {
        btn.classList.toggle("is-danger")
        if (Math.random() < 0.35) {
          btn.classList.toggle("animate__hinge")
          this.$audio_error1.play()
        }
        else {
          this.$audio_error2.play()
        }
        this.show_answer(true)
      }

      this.T++
      this.over = true
    },

    reset_page() {
      this.over = false
      this.T = 0
      this.C = 0
      this.new_question()
    },

    show_answer(show) {
      var ans_field = document.getElementById("show-answer")
      if (show) {
        ans_field.style.display = 'block'
      }
      else {
        ans_field.style.display = 'none'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
