<template>
  <div class="quiz-container">
    <div class="quiz-question box">
      <img src="@/assets/logo.png" class="bg-image" alt="Music theory practice 乐理练习"/>
      <div class="quiz-content">
        <!-- Update this part -->
        <div class="columns is-mobile" style="margin-bottom: -2.7em">
          <div class="column is-offset-two-thirds is-one-third">
            <button class="button btn-language is-small" @click="switchLanguage('en')">En</button>
            <button class="button btn-language is-small" @click="switchLanguage('zh')">中</button>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-one-quater">
            <h2 class="subtitle"><b>{{ $t('h_title') }}</b></h2>
          </div>
        </div>

        <div class="column is-full-width music-menu">
          <router-link to="/beats" class="button is-fullwidth quiz-option animate__rubberBand animate__animated">{{ $t('h_beats') }}</router-link>
          <router-link to="/interval" class="button is-fullwidth quiz-option animate__rubberBand animate__animated">{{ $t('h_interval') }}</router-link>
          <router-link to="/scale" class="button is-fullwidth quiz-option animate__rubberBand animate__animated">{{ $t('h_scale') }}</router-link>
          <router-link to="/chord" class="button is-fullwidth quiz-option animate__rubberBand animate__animated">{{ $t('h_chord') }}</router-link>
          <router-link to="/term" class="button is-fullwidth quiz-option animate__rubberBand animate__animated">{{ $t('h_term') }}</router-link>
        </div>

        <hr/>
        <router-link to="/about" class="button is-small btn-contact animate__backInUp animate__animated">
          ♬ 联系我们
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MusicHome',
  components: {
    // Contact
  },
  mounted() {
    var language = localStorage.getItem("locale")
    if (language) {
      this.$i18n.locale = language
    }
  },
  methods: {
    switchLanguage(language) {
      localStorage.setItem("locale",language)
      this.$i18n.locale = language
    }
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .bg-image {
    opacity: 0.15;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40%;
    transform: translate(-50%, -50%);
  }

  .btn-contact {
    padding-left: 5em;
    padding-right: 5em;
    color: #444;
  }

  .btn-language {
    width: 1px;
    margin-right: 2px;
  }
</style>
