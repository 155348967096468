<template>
  <div class="quiz-container">
    <div class="quiz-question box">
      <div class="quiz-content">
        <div class="columns is-mobile">
          <div class="column is-one-quater">
            <br/>
            <h2 class="subtitle"><b>关于我们</b></h2>
            <img src="@/assets/logo.png" class="about-logo-img"/>
            <p style="font-size: 1.1em">
              <b>沈阳市沈河区光与盐乐器商行</b> 专注于一对一真人指导在线音乐教育，旨在为全球学生提供方便快捷的音乐课程。
            </p>
            <br/>
            <p><b>教育理念</b><br/>通过互动式教育带领学生理解音乐，改善单调教育模式，旨在让每一位学生享受音乐带来的美妙与乐趣。</p>
            
            <br/>
            <p><b>专业课程</b><br/>
            我们的课程设置涵盖多种乐器、声乐课程、音乐理论课程，包括但不限于钢琴、大提琴、小提琴、吉他、美声声乐、<b>ABRSM/AMEB</b> 理论课程、和声课程等。</p>
            
            <br/>
            <p><b>教育团队</b><br/>我们的教师专业耐心且教育经验丰富，他们将根据学生的水平和需求，为每个学生提供个性化授课。</p>
            <p>光与盐乐器商行平台提供多种学习资源与练习工具，在线化的便捷线上教育平台可以让学生在光与盐乐器商行随时随地上课，并与他们的老师进行实时互动。</p>
            <br/>

            <p style="font-size: 1.1em">无论您身在何处，只要您拥有一台连接互联网的设备，光与盐乐器商行就能为您提供最优质的音乐教育服务。</p>
          </div>
        </div>  
      </div>
    </div>

    <div class="quiz-question box">
      <div class="quiz-content">
        <div class="columns is-mobile">
          <div class="column is-one-quater">
            <h2 class="subtitle"><b>联系方式</b></h2>

            <img src="@/assets/douyin.png" class="ad-qr"/>
            <div class="ad-text">
              <b>抖音: </b> 
              <a href="https://www.douyin.com/user/MS4wLjABAAAASN0NkZEXjdCAEr0U86VzCeK9R0rO6uPIaYoFCWZ1gB4">沈阳市沈河区光与盐乐器商行</a>
            </div>
            <br/>

            <img src="@/assets/weixin2.png" class="ad-qr"/>
            <div class="ad-text">
              <b>微信公众号</b>: gh_68334313580c
            </div>
            <br/>

            <img src="@/assets/taobao.png" class="ad-qr"/>
            <div class="ad-text">
              <b>淘宝店二维码</b>
            </div>
            <br/>
          </div>
        </div>  
      </div>
    </div>

    <router-link to="/" class="button is-small btn-contact">
      返回首页
    </router-link>

  </div>
</template>

<script>

export default {
  name: 'MusicAbout',
  components: {

  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .ad-qr {
    width: 60%;
  }

  .ad-text {
    font-size: 0.85em;
  }

  .about-logo-img {
    width: 40%;
    margin-top: -2em;
  }

  .bg-image {
    opacity: 0.10;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60%;
    transform: translate(-50%, -50%);
  }

  .btn-contact {
    padding-left: 5em;
    padding-right: 5em;
    color: #444;
  }

  p {
    text-align: left;
  }
</style>
