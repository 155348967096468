<template>
  <div class="quiz-container">
    <div class="quiz-question box">
      <img src="@/assets/logo.png" class="bg-image" />
      
      <div class="quiz-content">
        <div class="columns is-mobile">
          <div class="column is-one-quater">
            <h2 class="subtitle"><b>{{ $t('question') }}</b></h2>
          </div>
          <div class="column is-half" style="font-size: 1.2em; text-align: right; padding-right: 1.5em;">
            {{ C }} / {{ T }}
          </div>
        </div>

        <h2 class="subtitle" id="qtext">{{ $t('c_question') }}</h2>
        <div class="container">
          <div id="music-notes"></div>
        </div>

        <div class="buttons">
          
          <button v-for="option in options" :key="option.id" 
            @click="check_answer($event.target)"
            :value="option.opt"
            class="button is-medium is-outlined quiz-option" 
          >
            {{ option.opt }}
          </button>
          <br/>
        </div>

        <div id="show-answer" class="show-answer">
          <b>Answer: </b> {{ this.correct }}
          <br/>
        </div>
      </div>

    </div>
    <div class="field is-grouped is-mobile">
      <div class="column control is-half">
        <button class="button is-warning is-fullwidth" @click="reset_page()">{{ $t('reset') }}</button>
      </div>
      <div class="column control is-half">
        <button class="button is-primary is-fullwidth" @click="next_question()">{{ $t('next') }}</button>
      </div>
    </div>

    <Contact />
  </div>
</template>

<script>
import ABCJS from 'abcjs'
import Contact from './Contact.vue'

export default {
  name: 'MusicQuizScale',
  components: {
    Contact
  },
  data() {
    return {
      options: [
        {id: "A", opt: "Major Triad"},
        {id: "B", opt: "Minor Triad"},
        {id: "C", opt: "Augmented Triad"},
        {id: "D", opt: "Diminished Triad"}
      ],
      correct: 0,
      over: false,

      T: 0, // Total questions answered
      C: 0, // Total correct answers

      mappings: {
        'C':  {'m3': '_E', 'M3': 'E'}, 
        '^C': {'m3': 'E',  'M3': '^E'}, 
        'D':  {'m3': 'F',  'M3': '^F'}, 
        '^D': {'m3': '^F', 'M3': '^^F'}, 
        '_E': {'m3': '_G', 'M3': 'G'},
        'E':  {'m3': 'G',  'M3': '^G'}, 
        'F':  {'m3': '_A', 'M3': 'A'}, 
        '^F': {'m3': 'A',  'M3': '^A'}, 
        'G':  {'m3': '_B', 'M3': 'B'}, 
        '^G': {'m3': 'B',  'M3': '^B'}, 
        '_A': {'m3': '_c', 'M3': 'c'}, 
        'A':  {'m3': 'c',  'M3': '^c'}, 
        '_B': {'m3': '_d', 'M3': 'd'}, 
        'B':  {'m3': 'd',  'M3': '^d'},
        'c':  {'m3': '_e', 'M3': 'e'}, 
        '^c': {'m3': 'e',  'M3': '^e'}, 
        'd':  {'m3': 'f',  'M3': '^f'}, 
        '^d': {'m3': '^f', 'M3': '^^f'},
        '_e': {'m3': '_g', 'M3': 'g'},
        'e':  {'m3': 'g',  'M3': '^g'}, 
        'f':  {'m3': '_a', 'M3': 'a'}, 
        '^f': {'m3': 'a',  'M3': '^a'}
      }
    }
  },
  mounted() {
    this.new_question()
  },

  methods: {
    random_choice_single(arr) {
      const randomIndex = Math.floor(Math.random() * arr.length);
      return arr[randomIndex];
    },

    get_interval(base, interval) {
      return this.mappings[base][interval]
    },

    generate_question() {
      const bases = ["C", "D", "E", "F", "G", "A", "B", "c", "d"]

      const chord_types = [
        ["Major Triad", ["M3", "m3"]],
        ["Minor Triad", ["m3", "M3"]],
        ["Augmented Triad", ["M3", "M3"]],
        ["Diminished Triad", ["m3", "m3"]],
      ]

      var ansBase  = this.random_choice_single(bases)
      var ansChord = this.random_choice_single(chord_types)

      var k1 = ansBase
      var k2 = this.get_interval(k1, ansChord[1][0])
      var k3 = this.get_interval(k2, ansChord[1][1])
      return [ansBase, ansChord[0], [k1, k2, k3]]
    },

    translate(text) {
      if (this.$i18n.locale == "zh") {
        text = text.replace("Major Triad", "大三和弦")
        text = text.replace("Minor Triad", "小三和弦")
        text = text.replace("Augmented Triad", "增三和弦")
        text = text.replace("Diminished Triad", "减三和弦")
      }
      return text
    },

    new_question() {
      var tokens = this.generate_question()
      var ansChord = tokens[1]

      var k1 = tokens[2][0]
      var k2 = tokens[2][1]
      var k3 = tokens[2][2]

      var qnotes = "L:1/1\nV:V1 clef=" + "treble" + "\nK: C\n[" + k1 + k2 + k3 + "]"

      // 1. Reset all buttons
      var buttons = document.getElementsByClassName('quiz-option')
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].className = 'button is-medium is-outlined quiz-option'
      }
      this.over = false

      // // 2. Render Question
      var ctl_notes = document.getElementById("music-notes")
      ABCJS.renderAbc(ctl_notes, qnotes, { staffwidth: "350", responsive: "resize", paddingleft: 20 })

      // // 3. Render Options
      this.options[0]['opt'] = this.translate("Major Triad")
      this.options[1]['opt'] = this.translate("Minor Triad")
      this.options[2]['opt'] = this.translate("Augmented Triad")
      this.options[3]['opt'] = this.translate("Diminished Triad")

      this.correct = this.translate(ansChord)
      this.show_answer(false)
    },

    next_question() {
      if (this.over) {
        this.new_question()
      }
    },

    check_answer(btn) {
      if (this.over) {
        return ;
      }

      if (btn.value == this.correct) {
        btn.classList.toggle("is-success")
        this.$audio_correct.play()
        this.C++
      }
      else {
        btn.classList.toggle("is-danger")
        if (Math.random() < 0.35) {
          btn.classList.toggle("animate__hinge")
          this.$audio_error1.play()
        }
        else {
          this.$audio_error2.play()
        }
        this.show_answer(true)
      }

      this.T++
      this.over = true
    },

    reset_page() {
      this.over = false
      this.T = 0
      this.C = 0
      this.new_question()
    },

    show_answer(show) {
      var ans_field = document.getElementById("show-answer")
      if (show) {
        ans_field.style.display = 'block'
      }
      else {
        ans_field.style.display = 'none'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
