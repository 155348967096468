import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { createI18n } from 'vue-i18n'
import en from './i18n/en.js';
import zh from './i18n/zh.js';

const i18n = createI18n({
  locale: 'en',
  messages: {en, zh}
})

const app = createApp(App)
app.use(router)
app.use(i18n)
app.config.globalProperties.$audio_correct = new Audio(require('@/assets/correct.mp3'))
app.config.globalProperties.$audio_error1  = new Audio(require('@/assets/error1.mp3'))
app.config.globalProperties.$audio_error2  = new Audio(require('@/assets/error2.mp3'))
app.mount('#app')
