class Scale {

    constructor() {
        this.sharp_pitches = ["A", "A#", "B", "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#"]
        this.flat_pitches  = ["A", "Bb", "B", "C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab"]

        this.sharp_bases = ["C", "G", "D", "A", "E", "B", "F#", "C#"]
        this.flat_bases  = ["F", "Bb", "Eb", "Ab", "Db", "Gb", "Cb"]

        this.gaps = [2, 2, 1, 2, 2, 2, 1]

        this.minor_mapping = {
            "A": "C",
            "E": "G",
            "B": "D",
            "F#": "A",
            "C#": "E",
            "G#": "B",
            "D#": "F#",
            "A#": "C#",
            "D": "F",
            "G": "Bb",
            "C": "Eb",
            "F": "Ab",
            "Bb": "Db",
            "Eb": "Gb",
            "Ab": "Cb"
        }

        this.correction = {
            "F#": {"F": "E#"},
            "C#": {"F": "E#", 
                   "C": "B#"},
            "Gb": {"B": "Cb"},
            "Cb": {"B": "Cb",
                   "E": "Fb"}
        }
    }

    refine_scale(base, scale) {
        if (base in this.correction) {
            var mapping = this.correction[base]
            for (var i = 0; i < scale.length; i++) {
                if (scale[i] in mapping) {
                    scale[i] = mapping[ scale[i] ]
                }
            }
        }
        return scale
    }

    get_major_scale(base) {
        var result = [ base ]

        // Check with group the base belongs to.
        var pitches = this.sharp_pitches
        if (this.flat_bases.indexOf(base) >= 0 || base == "Cb") {
            pitches = this.flat_pitches
        }

        var idx = pitches.indexOf(base)
        if (base == "Cb") {
            idx = pitches.indexOf("B")
        }
        for (var i = 0; i < this.gaps.length; i++) {
            idx = (idx + this.gaps[i]) % pitches.length
            result.push( pitches[idx] )
        }

        result = this.refine_scale(base, result)
        return result
    }

    raise(pitch) {
        if (pitch.length == 1) {
            return pitch + "#"
        }
        else if (pitch.length == 2) {
            if (pitch[1] == "#") {
                return pitch + "#"
            }
            else {
                return "" + pitch[0]
            }
        }
        else {
            return "ERROR"
        }
    }

    get_natural_minor_scale(base) {
        var major_base  = this.minor_mapping[base]
        var major_scale = this.get_major_scale(major_base)
        major_scale.pop()

        var base_idx = major_scale.indexOf(base)
        var result = []
        for (var i = 0; i < major_scale.length; i++) {
            result.push( major_scale[(base_idx + i) % major_scale.length] )
        }
        result.push(base)
        return result
    }

    get_harmonic_minor_scale(base) {
        var result = this.get_natural_minor_scale(base)
        result[6] = this.raise(result[6])
        return result
    }

    get_melodic_minor_scale_ascending(base) {
        var result = this.get_natural_minor_scale(base)
        result[5] = this.raise(result[5])
        result[6] = this.raise(result[6])
        return result
    }

    get_melodic_minor_scale_descending(base) {
        var result = this.get_natural_minor_scale(base)
        var j = result.length - 1
        var tmp = result[0]
        for (var i = 0; i < result.length/2; i++) {
            tmp = result[i]
            result[i] = result[j]
            result[j] = tmp
            --j;
        }
        return result
    }

    to_abcjs(pitch, octave=0) {
        var sharps = pitch.match(/#+/)
        var flats  = pitch.match(/b/)
        var result = pitch.match(/\w/)[0]

        sharps = sharps ? sharps[0].length : 0
        flats  = flats  ? flats[0].length : 0

        var i = 0
        for (i = 0; i < sharps; i++) {
            result = "^" + result
        }
        for (i = 0; i < flats; i++) {
            result = "_" + result
        }

        if (octave > 0) {
            for (i = 0; i < octave; i++) {
                result = result + "'"
            }
        }
        else {
            for (i = 0; i < -octave; i++) {
                result = result + ","
            }
        }
        return result
    }

    scale_to_abcjs(scale, octave=0, descending=false) {
        var result = []
        var i = 0
        if (!descending) {
            for (i = 0; i < scale.length; i++) {
                if (i != 0 && scale[i].match(/C/)) {
                    octave = octave + 1
                }
                result.push(this.to_abcjs(scale[i], octave))
            }
        }
        else {
            for (i = 0; i < scale.length; i++) {
                if (i != 0 && scale[i].match(/B/)) {
                    octave = octave - 1
                }
                result.push(this.to_abcjs(scale[i], octave))
            }
        }
        return result.join(" ")
    }
}

export default Scale