export default {
  // Global
  question: "Question",
  reset: "Reset",
  next:  "Next",

  // Homepage
  h_title:    "Music Theory Practice",
  h_beats:    "Beats Practice",
  h_interval: "Interval Practice",
  h_scale:    "Scale Practice",
  h_chord:    "Chord Practice",
  h_term:     "Terminologies",

  // Beats
  b_question:  "How many beats are there?",

  // Scale
  s_question:  "What is this scale?",

  // Chord
  c_question: "What is this chord?",

  // Term
  t_question: "What's the meaning of this term?"
};