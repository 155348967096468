<template>
  <div class="quiz-container">
    <div class="quiz-question box">
      <img src="@/assets/logo.png" class="bg-image" />
      
      <div class="quiz-content">
        <div class="columns is-mobile">
          <div class="column is-one-quater">
            <h2 class="subtitle"><b>{{ $t('question') }}</b></h2>
          </div>
          <div class="column is-half" style="font-size: 1.2em; text-align: right; padding-right: 1.5em;">
            {{ C }} / {{ T }}
          </div>
        </div>

        <h2 class="subtitle" id="qtext">{{ $t('t_question') }}</h2>
        <div class="container">
          <!-- <div id="music-notes"></div> -->
          <div id="music-term"></div>
        </div>

        <div class="buttons">
          <button v-for="option in options" :key="option.id" 
            @click="check_answer($event.target)"
            :value="option.opt"
            class="button is-medium is-outlined quiz-option" 
          >
            {{ option.opt }}
          </button>
          <br/>
        </div>

        <div id="show-answer" class="show-answer">
          <b>Answer: </b> {{ this.correct }}
          <br/>
        </div>
      </div>

    </div>
    <div class="field is-grouped is-mobile">
      <div class="column control is-half">
        <button class="button is-warning is-fullwidth" @click="reset_page()">{{ $t('reset') }}</button>
      </div>
      <div class="column control is-half">
        <button class="button is-primary is-fullwidth" @click="next_question()">{{ $t('next') }}</button>
      </div>
    </div>

    <Contact />
  </div>
</template>

<script>
import Contact from './Contact.vue'

export default {
  name: 'MusicQuizTerm',
  components: {
    Contact
  },
  data() {
    return {
      term_list: [
        ["agitato", "agitated", "激动地"],
        ["andantino", "slightly faster than andante", "稍快于行板"],
        ["animato", "animated, lively", "生气勃勃的，活泼地"],
        ["ben", "well, very", "充分地、非常"],
        ["con forza", "with force", "有力量地"],
        ["energico", "energetic", "精力充沛地"],
        ["forza", "force", "力量"],
        ["giocoso", "playful, merry", "嬉戏的，快乐的"],
        ["leggiero", "light", "轻的，轻巧的"],
        ["maestoso", "majestic", "壮观的，宏伟的"],
        ["marcato", "emphatic, accented", "强调的，加重的"],
        ["marc.", "emphatic, accented", "强调的，加重的"],
        ["pesante", "heavy", "沉重有力地"],
        ["prestissimo", "very fast", "非常快，最急板"],
        ["prima", "first", "第一"],
        ["primo", "first", "第一"],
        ["risoluto ", "bold, strong", "勇敢地，坚强地"],
        ["scherzando", "playful, joking", "嬉戏地，诙谐地"],
        ["semplice", "simple, plain", "简单地，朴素地"],
        ["sempre", "always", "一直，始终"],
        ["sforzando", "accented, forced", "加重的，突强的"],
        ["sforzato", "accented, forced", "加重的，突强的"],
        ["sf", "accented, forced", "加重的，突强的"],
        ["sfz", "accented, forced", "加重的，突强的"],
        ["simile", "in the same way", "以相同的方式"],
        ["sim.", "in the same way", "以相同的方式"],
        ["sostenuto", "sustained", "持续的"],
        ["soft.", "sustained", "持续的"],
        ["subito", "suddenly", "突然地"],
        ["sub.", "suddenly", "突然地"],
        ["tranquillo", "caim", "平静地"],
        ["triste", "sad, sorrowful", "忧伤地，悲痛地"],
        ["tristamente", "sad, sorrowful", "忧伤地，悲痛地"],
        ["affettuoso", "tenderly", "温柔地"],
        ["alla breve", "with a minim beat", "以二分音符为一拍"],
        ["amabile", "pleasant, amiable", "愉快的，亲切的"],
        ["appassionato", "with passion", "热情的，激情的"],
        ["assai", "very", "非常"],
        ["brio", "vigour", "活力"],
        ["come prima", "as before", "如前"],
        ["comodo", "comfortable", "舒适的"],
        ["con brio", "with vigour, lively", "充满活力的，活泼的"],
        ["deciso", "with determination", "坚定的"],
        ["larghetto", "rather slow", "颇慢，小广板"],
        ["mesto", "sad", "悲伤地"],
        ["misterioso", "mysterious ", "神秘的"],
        ["ritmico", "rhythmically", "富节奏感地"],
        ["rubato", "with some freedom of time", "弹性速度"],
        ["stringendo", "gradually getting faster", "渐快"],
        ["tempo comodo", "at a comfortable speed", "舒适的速度"],
        ["tempo rubato", "with some freedom of time", "弹性速度"],
        ["animé", "lively, animated", "活泼的，生气勃勃的"],
        ["douce", "sweet", "甜蜜的"],
        ["lent", "slow", "慢"],
        ["modéré", "at a moderate speed", "中板，适中的速度"],
        ["retenu", "held back", "突慢（立刻放慢）"],
        ["vite", "quick", "快，快板"],
        ["a tempo", "in time (return to original speed)", "回復原来速度"],
        ["accelerando", "gradually getting quicker", "渐快"],
        ["accel.", "gradually getting quicker", "渐快"],
        ["adagio  ", "slow (at ease) ", "慢 (舒适)"],
        ["allegretto", "fairly quick", "稍快/小快板"],
        ["allegro", "quick", "快/快板 (欢乐)"],
        ["andante", "at a medium speed", "行板，中速 (行走的速度)"],
        ["cantabile", "in a singing style", "如歌的风格"],
        ["crescendo", "gradually getting louder", "渐强"],
        ["cresc.", "gradually getting louder", "渐强"],
        ["da capo", "repeat from the beginning", "从头再奏"],
        ["D.C.", "repeat from the beginning", "从头再奏"],
        ["decrescendo", "gradually getting quieter", "渐弱"],
        ["decresc.", "gradually getting quieter", "渐弱"],
        ["diminuendo", "gradually getting quieter", "渐弱"],
        ["dim.", "gradually getting quieter", "渐弱"],
        ["dolce", "sweet, soft", "甜蜜的，温柔的"],
        ["fine", "the end", "结束"],
        ["forte", "loud", "强，响亮"],
        ["f", "loud", "强，响亮"],
        ["fortissimo", "very loud", "很强"],
        ["ff", "very loud", "很强"],
        ["legato", "smoothly", "连贯地，圆滑地"],
        ["mezzo", "half (moderately)", "一半 (适中)"],
        ["mezzo forte", "moderately loud", "中强"],
        ["mf", "moderately loud", "中强"],
        ["mezzo piano", "moderately quiet ", "中弱"],
        ["mp", "moderately quiet ", "中弱"],
        ["moderato", "at a moderate speed", "中板，适中的速度"],
        ["piano", "quiet", "弱"],
        ["p", "quiet", "弱"],
        ["pianissimo", "very quiet", "很弱"],
        ["pp", "very quiet", "很弱"],
        ["rallentando", "gradually getting slower", "渐慢"],
        ["rall.", "gradually getting slower", "渐慢"],
        ["ritardando", "gradually getting slower", "渐慢"],
        ["ritard.", "gradually getting slower", "渐慢"],
        ["rit.", "gradually getting slower", "渐慢"],
        ["staccato", "detached", "断奏，断音"],
        ["stacc.", "detached", "断奏，断音"],
        ["alla marcia", "in the style of a march", "进行曲风格"],
        ["allargando", "getting slower and louder", "宽广的 (渐慢及渐强)"],
        ["con", "with", "含有"],
        ["col", "with", "含有"],
        ["con moto", "with movement", "稍快"],
        ["espressivo", "expressive", "有表情的"],
        ["espress.", "expressive", "有表情的"],
        ["espr.", "expressive", "有表情的"],
        ["fortepiano", "loud, then immediately quiet", "先强然后立即转弱"],
        ["fp", "loud, then immediately quiet", "先强然后立即转弱"],
        ["grave", "very slow, solemn", "非常慢，庄严的"],
        ["grazioso", "graceful", "优雅的"],
        ["largo", "slow, broad", "慢，宽广"],
        ["lento", "slow", "慢"],
        ["ma", "but", "但是"],
        ["meno", "less", "少些"],
        ["meno mosso", "less moved (slower)", "稍慢"],
        ["molto", "much, very", "十分，很"],
        ["mosso", "moved", "活动的"],
        ["moto", "movement", "活动"],
        ["non", "not", "不是"],
        ["non troppo", "more moved (quicker)", "稍快"],
        ["poco", "a little", "少许"],
        ["poco a poco", "little by little", "逐渐地"],
        ["presto", "fast (faster than allegro)", "快 (比快板allegro快)，急板"],
        ["ritenuto", "immediately slowing down", "突慢 (立即放慢)"],
        ["riten.", "immediately slowing down", "突慢 (立即放慢)"],
        ["rit.", "immediately slowing down", "突慢 (立即放慢)"],
        ["senza", "without", "没有，去除"],
        ["troppo", "too much", "太多"],
        ["vivace", "lively, quick", "有生气的，轻快的"],
        ["vivo", "lively, quick", "有生气的，轻快的"],
        ["a niente", "to nothing", "直至什么都没有"],
        ["ad libitum", "at choice ", "随意处理"],
        ["attacca", "go straight on", "紧接"],
        ["cantando", "singing", "如歌的"],
        ["con dolore", "with grief", "悲痛地"],
        ["con spirito", "with spirit", "精神饱满地"],
        ["dolore", "grief", "悲痛"],
        ["doloroso", "sorrowful", "悲痛的"],
        ["largamente", "broadly", "宽广地"],
        ["morendo", "dying away", "逐渐消失"],
        ["niente", "nothing", "没有甚至"],
        ["perdendosi", "dying away", "逐渐消失"],
        ["quasl", "as if", "好像"],
        ["rinforzando", "reinforcing", "加强"],
        ["rinf.", "reinforcing", "加强"],
        ["smorzando", "dying away", "逐渐消失"],
        ["sonoro", "resonant", "响亮地"],
        ["sotto voce", "in an undertone", "轻声地"],
        ["langsam", "slow", "慢"],
        ["massig", "at a moderate speed", "中板，适中的速度"],
        ["ruhig", "peaceful", "平静的"],
        ["schnell", "fast", "快"],
        ["traurig", "sad", "悲痛地"]
      ],
      options: [
        {id: "A", opt: "fast"},
        {id: "B", opt: "slow"},
        {id: "C", opt: "very fast"},
        {id: "D", opt: "very slow"}
      ],
      correct: 0,
      over: false,

      T: 0, // Total questions answered
      C: 0, // Total correct answers
    }
  },
  mounted() {
    this.new_question()
  },

  methods: {


    clearString(s) {
        s = s.replace("的", "")
        s = s.replace("地", "")
        return s
    },

    getSimilarity(x, y) {
        x = this.clearString(x)
        y = this.clearString(y)
        const set1 = new Set(x);
        const set2 = new Set(y);

        const intersection = new Set([...set1].filter((x) => set2.has(x)));
        const similarity = intersection.size / ((x.length + y.length) / 2);
        return similarity;
    },

    similarToAny(x, lst) {
        for (var i = 0; i < lst.length; i++) {
            if (this.getSimilarity(x, lst[i]) > 0.39) {
                return true
            }
        }
        return false
    },

    chooseNItems(arr, N) {
        var str_cand = []
        var all_cand = []
        for (var i = 0; i < N; i++) { 
            var idx = Math.floor(Math.random() * arr.length)
            while ( this.similarToAny(arr[idx][2], str_cand) ) {
                idx = Math.floor(Math.random() * arr.length)
            }
            str_cand.push( arr[idx][2] )
            all_cand.push( arr[idx] )
        }
        return all_cand
    },


    generate_question() {
      var candidates = this.chooseNItems(this.term_list, 4)
      var question = candidates[0]
      var options  = []
      var LANG_COL = this.$i18n.locale == "zh" ? 2 : 1

      for (var i = 0; i < candidates.length; i++) {
        options.push( candidates[i][LANG_COL] )
      }
      options.sort(() => 
        Math.random() - 0.5
      );
      return [question[0], question[LANG_COL], options]
    },

    new_question() {
      var tokens = this.generate_question()
      var question = tokens[0]
      var answer   = tokens[1]
      var options  = tokens[2]

      // 1. Reset all buttons
      var buttons = document.getElementsByClassName('quiz-option')
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].className = 'button is-medium is-outlined quiz-option'
      }
      this.over = false

      // 2. Render Question
      var ctl_term = document.getElementById("music-term")
      ctl_term.innerText = question

      // 3. Render Options
      for (i = 0; i < options.length; i++) {
        this.options[i]['opt'] = options[i]

      }
      this.correct = answer
      this.show_answer(false)
    },

    next_question() {
      if (this.over) {
        this.new_question()
      }
    },

    check_answer(btn) {
      if (this.over) {
        return ;
      }

      if (btn.value == this.correct) {
        btn.classList.toggle("is-success")
        this.$audio_correct.play()
        this.C++
      }
      else {
        btn.classList.toggle("is-danger")
        if (Math.random() < 0.35) {
          btn.classList.toggle("animate__hinge")
          this.$audio_error1.play()
        }
        else {
          this.$audio_error2.play()
        }
        this.show_answer(true)
      }

      this.T++
      this.over = true
    },

    reset_page() {
      this.over = false
      this.T = 0
      this.C = 0
      this.new_question()
    },

    show_answer(show) {
      var ans_field = document.getElementById("show-answer")
      if (show) {
        ans_field.style.display = 'block'
      }
      else {
        ans_field.style.display = 'none'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#music-term {
  padding: 20;
  font-size: 1.4em;
  font-family: "Serif";
  font-weight: bold;
  margin-top: -0.6em;
  margin-bottom: 0.5em;
  color: #333;
}

button.quiz-option {
  font-size: 0.9em;
  height: 2.8em;
  color: #111;
}
</style>
