<template>
  <div class="quiz-container">
    <div class="quiz-question box">
      <img src="@/assets/logo.png" class="bg-image" />
      
      <div class="quiz-content">
        <div class="columns is-mobile">
          <div class="column is-one-quater">
            <h2 class="subtitle"><b>{{ $t('question') }}</b></h2>
          </div>
          <div class="column is-half" style="font-size: 1.2em; text-align: right; padding-right: 1.5em;">
            {{ C }} / {{ T }}
          </div>
        </div>

        <h2 class="subtitle" id="qtext">{{ $t('b_question') }}</h2>
        <div class="container">
          <div id="music-notes"></div>
        </div>

        <div class="buttons">
          
          <button v-for="option in options" :key="option.id" 
            @click="check_answer($event.target)"
            :value="option.opt"
            class="button is-medium is-outlined quiz-option" 
          >
            {{ option.opt }}
          </button>
          <br/>
        </div>

        <div id="show-answer" class="show-answer">
          <b>Answer: </b> {{ this.correct }}
          <br/>
        </div>
      </div>

    </div>
    <div class="field is-grouped is-mobile">
      <div class="column control is-half">
        <button class="button is-warning is-fullwidth" @click="reset_page()">{{ $t('reset') }}</button>
      </div>
      <div class="column control is-half">
        <button class="button is-primary is-fullwidth" @click="next_question()">{{ $t('next') }}</button>
      </div>
    </div>

    <Contact />
  </div>
</template>

<script>
import ABCJS from 'abcjs'
import Contact from './Contact.vue'

export default {
  name: 'MusicQuizBeats',
  components: {
    Contact
  },
  data() {
    return {
      options: [
        {id: "A", opt: "3.5"},
        {id: "B", opt: "4.5"},
        {id: "C", opt: "5"},
        {id: "D", opt: "5.5"}
      ],
      correct: 0,
      over: false,

      T: 0, // Total questions answered
      C: 0, // Total correct answers
    }
  },
  mounted() {
    this.new_question()
  },

  methods: {
    random_choices(arr, numItems) {
      const result = [];
      for (let i = 0; i < numItems; i++) {
        const randomIndex = Math.floor(Math.random() * arr.length);
        result.push(arr[randomIndex]);
        arr.splice(randomIndex, 1);
      }
      return result;
    },

    generate_question() {
      var mapping = [
        ["A", 1],
        ["A1/2A1/2", 1],
        ["A3/4A1/4", 1],
        ["A1/4A3/4", 1],
        ["A1/4A1/4", 0.5],
        ["A1/2", 0.5],
        ["A1/4A1/4A1/4A1/4", 1],
        ["A1/2A1/4A1/4", 1],
        ["A1/4A1/4A1/2", 1],
        ["A1/4A1/2A1/4", 1],
        ["A1/2A", 1.5]
      ]
      var num = Math.floor(Math.random() * 4) + 2
      var choices  = this.random_choices(mapping, num)
      var notes = choices.map(item => item[0]).join(" ")
      var total = choices.reduce((accumulator, currentValue) => accumulator + currentValue[1], 0)
      return [notes, total]
    },

    generate_options(correct_ans) {
      var temp = [2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5]
      
      var tempArray = temp.filter(function(element) {
        return element !== correct_ans;
      });

      var choices = this.random_choices(tempArray, 3)
      choices.push(correct_ans)
      choices.sort(function(a, b) {
        return a - b;
      });
      return choices
    },

    new_question() {
      var question = this.generate_question()
      var qnotes = "L:1/4\nK: C\n" + question[0]
      var qanswr = question[1]
      var options = this.generate_options(qanswr)

      // 1. Reset all buttons
      var buttons = document.getElementsByClassName('quiz-option')
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].className = 'button is-medium is-outlined quiz-option'
      }
      this.over = false

      // 2. Render Question
      var ctl_notes = document.getElementById("music-notes")
      ABCJS.renderAbc(ctl_notes, qnotes, { staffwidth: "350", responsive: "resize" })

      // 3. Render Options
      for (i = 0; i < options.length; i++) {
        this.options[i]['opt'] = options[i]
      }
      this.correct = qanswr
      this.show_answer(false)
    },

    next_question() {
      if (this.over) {
        this.new_question()
      }
    },

    check_answer(btn) {
      if (this.over) {
        return ;
      }

      if (btn.value == this.correct) {
        btn.classList.toggle("is-success")
        this.$audio_correct.play()
        this.C++
      }
      else {
        btn.classList.toggle("is-danger")
        if (Math.random() < 0.35) {
          btn.classList.toggle("animate__hinge")
          this.$audio_error1.play()
        }
        else {
          this.$audio_error2.play()
        }
        this.show_answer(true)
      }

      this.T++
      this.over = true
    },

    reset_page() {
      this.over = false
      this.T = 0
      this.C = 0
      this.new_question()
    },

    show_answer(show) {
      var ans_field = document.getElementById("show-answer")
      if (show) {
        ans_field.style.display = 'block'
      }
      else {
        ans_field.style.display = 'none'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
