<template>
  <RouterView />
</template>

<script>
import { RouterView } from 'vue-router';
import 'bulma/css/bulma.css'
import 'animate.css'

export default {
  name: 'App',
  components: {
    RouterView
  }
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 5px;
  }

  h2.subtitle {
    text-align: left;
    margin-left: 0.5em;
  }

  div.music-menu {
    width: 96%;
    margin-left: 2%;
  }

  div.music-menu a {
    margin-bottom: 0.5em;
    height: 50px;
  }

  .music-notes {
    width: 100%;
  }

  .quiz-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .quiz-question {
    margin-bottom: 1rem;

    overflow: hidden;
    position: relative;
  }
  
  .bg-image {
    opacity: 0.15;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
  }

  .quiz-content {
    position: relative;
  }

  .quiz-option {
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;
    opacity: 0.78;
    font-size: 1.15em;
  }

  .correct {
    background-color: #48c774;
    color: white;
  }
  
  .incorrect {
    background-color: #f14668;
    color: white;
  }

  .show-answer {
    text-align: left;
    margin-left: 2rem;
    margin-top: -1rem;
  }
</style>
