<template>
  <div class="contact-us">
    <p></p>
    <router-link to="/about">♬ 关于我们</router-link>
  </div>
</template>

<script>
export default {
  name: 'Contact',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contact-us {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 7px;
  font-size: 0.8em;
  background-color: rgba(200, 200, 200, 0.3);
  width: 100%;
}

.contact-us a {
  color: #0069c2;
  text-decoration: none;
}

</style>
