import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/Home.vue'
import Beats from './components/Beats.vue'
import Interval from './components/Interval.vue'
import Scale from './components/Scale.vue'
import Chord from './components/Chord.vue'
import Term from './components/Term.vue'

import About from './components/About.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/beats',
    name: 'beats',
    component: Beats
  },
  {
    path: '/interval',
    name: 'interval',
    component: Interval
  },
  {
    path: '/scale',
    name: 'scale',
    component: Scale
  },
  {
    path: '/chord',
    name: 'chord',
    component: Chord
  },
  {
    path: '/term',
    name: 'term',
    component: Term
  },
  {
    path: '/about',
    name: 'about',
    component: About
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
